import { useNavigate } from "react-router-dom";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import * as styled from "./TrialLessons.styled"
import { useEffect, useState } from "react";
import axios from '../../../axios'
import { RemoveIcon } from "../../../styles/components/RemoveIcon";
function TrialLessons(){
   const navigate = useNavigate()
   const [lessons, setLessons] = useState([])

   useEffect(() => { 
      axios.get('trial-lesson')
      .then(res => {
         setLessons(res.data)
      }).catch(err => console.error(err));
   },[])
  console.log(lessons)
  const handleRemove = (lessonId, teacherId) => {
    axios.delete(`trial-lesson/${lessonId}/${teacherId}`)
    .then(() => {
      const newLessons = lessons.filter(lesson => lesson._id !== lessonId)

      setLessons(newLessons)
    }).catch(err => console.error(err));
  }
  return (
    <PagesWrapper>
       <TopPageTitle value={"Пробні уроки"}/>

       <styled.List>
         <styled.AddTrialItem onClick={() => navigate(`add-trial-lesson`)}>
               <styled.ItemTitle>+ Пробний урок</styled.ItemTitle>
         </styled.AddTrialItem>
         {lessons.map(({name, studentId, _id, teacherId}) => (
            <styled.TrialItem key={_id}>
               <styled.ItemTitle>{name} - id({studentId})</styled.ItemTitle>
               <RemoveIcon onClick={() => handleRemove(_id, teacherId)}/>
            </styled.TrialItem>
         ))}
       </styled.List>
    </PagesWrapper>
  )
};

export default TrialLessons;
