import { useDispatch } from "react-redux";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./СlassSchedule.styled";
import { useState, useEffect } from "react";
import { cancelLesson } from "../../../helpers/cancelLesson/cancelLesson";
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled';
import axios from "../../../axios";
import useAuthData from "../../../hooks/useAuthData";
import Loader from "../../../components/Loader/Loader";

const days = [
  { name: "Понеділок", value: "monday" },
  { name: "Вівторок", value: "tuesday" },
  { name: "Середа", value: "wednesday" },
  { name: "Четвер", value: "thursday" },
  { name: "П'ятниця", value: "friday" },
  { name: "Субота", value: "saturday" },
  { name: "Неділя", value: "sunday" },
];

function СlassSchedule() {
  const dispatch = useDispatch();
  const data = useAuthData(dispatch);
  const [openItem, setOpenItem] = useState(false);
  const [handleCancel, setHandleCancel] = useState(false);
  const [checkbox, setCheckbox] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [statusSubmit, setStatusSubmit] = useState(null);
  const [googleMeetLinks, setGoogleMeetLinks] = useState({});
  const [reschedules, setReschedules] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState({day: "", time:""});
  useEffect(() => {
    if (!data) return;
    axios.get(`lesson-reschedule/student/${data._id}`)
      .then(res => setReschedules(res.data.lesson))
      .catch(err => console.error(err));
  }, [data]);

  useEffect(() => {
    const fetchLinks = async () => {
      if (!data) return;
      const links = {};
      if (reschedules.length > 0) {
        for (let reschedule of reschedules) {
          const teacherId = reschedule.type === 'teacher-changed' ? reschedule.teacherId : data.teacherId;
          links[reschedule.lessonReschedule.day] = await getMeetLink(teacherId);
        }
      }
      const regularLink = await getMeetLink(data.teacherId);
      days.forEach(day => {
        if (!links[day.value]) {
          links[day.value] = regularLink;
        }
      });
      setGoogleMeetLinks(links);
    };
    fetchLinks();
  }, [data, reschedules]);

  if (!data || !reschedules) {
    return <Loader />;
  }
  const handleSubmit = () => {
    if (checkbox.length === 0) return;
    setSelected(checkbox);
    if(checkbox === 'cancel') {
      let teacherId;

      reschedules.some(lesson => {
        if (lesson.lessonReschedule.day === selectedLesson.day || lesson.lessonReschedule.time === selectedLesson.time) {
          teacherId = lesson.teacherId;
          return true; 
        }
        return false;
      });
      if (teacherId === undefined) {
        teacherId = data.teacherId;
      }
      axios.post(`lesson-reschedule/cancel/${data._id}/${teacherId}`, { lessonReschedule: selectedLesson })

      .then(() => {
        console.log('successfully')
        cancelLesson(data._id, checkbox, selectedLesson).then(data => setStatusSubmit(data.status));
      })
      .catch(() => console.log('Щось пішло не так'))   
    }
    if (checkbox === 'reschedule') {
      cancelLesson(data._id, checkbox, selectedLesson).then(data => setStatusSubmit(data.status));
    }
  };

  const getMeetLink = async (teacherId) => {
    try {
      const response = await axios.get(`/teachers/${teacherId}`);
      return response.data.googleMeetLink;
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  const joinedLesson = () => {
    let teacherName
    reschedules.forEach(lesson => {
      if(lesson.lessonReschedule.day === selectedLesson.day || lesson.lessonReschedule.time === selectedLesson.time) {  
        teacherName = lesson.teacherName
      }else{
        teacherName = data.teacherName
      }
    })
    
    axios.post("student/joined-lesson", {
      lessonData: {
        teacherName: teacherName ? teacherName : data.teacherName,
        studentName: data.name,
        studentId: data.studentId,
        lessonTime: `${selectedLesson.day}, ${selectedLesson.time}`,
      },
    })
   
  };
 
  return (
    <PagesWrapper openItem={openItem}>
      <TopPageTitle value={"Розклад"} />
      <styled.List>
        {data && days.map(({ name, value }) => {
          const schedule = data.classSchedule.find(res => res.day === value);
          const reschedule = reschedules.find(({ lessonReschedule }) => lessonReschedule.day === value && lessonReschedule.time !== (schedule && schedule.time));
          const isItemOpen = selectedDay === value;
          const rescheduleTime = reschedule ? reschedule.lessonReschedule.day === value ? reschedule.lessonReschedule.time : '' : '';
          const canceled = reschedules.find(({ lessonReschedule }) => lessonReschedule.day === value && lessonReschedule.time === schedule?.time);
          const isCanceled= Boolean(canceled) ? canceled.type === 'cancel' : false;
         
          return (
            <styled.Item key={value}>
              <styled.BoxWrapper
                isItemOpen={isItemOpen}
                isSchedule={Boolean(schedule)}
                isReschedule={Boolean(reschedule)}
                isCanceled={isCanceled}
                onClick={() => {
                  if((!Boolean(schedule) && !Boolean(reschedule)) || isCanceled) return
                  setOpenItem(prev => !prev);
                  setSelectedDay(value);
                  setSelectedLesson({day: value, time: schedule?.time})
                }}
              >
                {reschedule && <styled.RescheduleText>Перенесений урок</styled.RescheduleText>}
                {isCanceled && <styled.RescheduleText isCanceled>Урок скасовано</styled.RescheduleText>}

                <styled.Day>{name}</styled.Day>
                <styled.Time>{rescheduleTime || schedule?.time}</styled.Time>
              </styled.BoxWrapper>

              <styled.ConnectWrapper isItemOpen={isItemOpen}>
                <styled.CloseButton onClick={() => {
                  setSelectedDay('');
                  setOpenItem(false);
                }} />
                <styled.connectLink
                  href={googleMeetLinks[value] ? googleMeetLinks[value] : ''}
                  target="_blank"
                  onClick={() => joinedLesson()}
                >
                  Приєднатись до уроку
                </styled.connectLink>
                <styled.cancelLesson
                  onClick={() => setHandleCancel(prev => !prev)}
                >
                  Скасувати урок
                </styled.cancelLesson>
                {handleCancel && (
                  <styled.FormWrapper>
                    {selected.length === 0 && (
                      <styled.FormTitle>
                        Пам'ятайте, що для прогресу важливі регулярні заняття
                      </styled.FormTitle>
                    )}
                    {selected === "cancel" && (
                      <styled.FormTitle>
                        Урок успішно скасовано!
                      </styled.FormTitle>
                    )}
                    {selected === "reschedule" && (
                      <styled.FormTitle>
                        Дякую! Менеджер скоро з вами зв'яжеться
                      </styled.FormTitle>
                    )}
                    {statusSubmit === 500 && (
                      <styled.FormTitle>
                        Щось пішло не так... Зв'яжіться з вашим менеджером
                      </styled.FormTitle>
                    )}
                    {selected.length === 0 && (
                      <>
                        <styled.CheckboxWrapper>
                          <styled.Input
                            isChecked={checkbox === "cancel"}
                            onClick={() => setCheckbox("cancel")}
                          />
                          <styled.Label htmlFor="cancel">
                            Відмінити урок
                          </styled.Label>
                        </styled.CheckboxWrapper>
                        <styled.CheckboxWrapper>
                          <styled.Input
                            isChecked={checkbox === "reschedule"}
                            onClick={() => setCheckbox("reschedule")}
                          />
                          <styled.Label htmlFor="reschedule">
                            Перенести урок
                          </styled.Label>
                        </styled.CheckboxWrapper>
                      </>
                    )}
                    {selected.length === 0 && (
                      <styled.SubmitButton onClick={handleSubmit}>
                        Відправити
                      </styled.SubmitButton>
                    )}
                    {selected.length !== 0 && (
                      <styled.SubmitButton onClick={() => setOpenItem(prev => !prev)}>
                        Закрити
                      </styled.SubmitButton>
                    )}
                  </styled.FormWrapper>
                )}
              </styled.ConnectWrapper>
            </styled.Item>
          );
        })}
      </styled.List>
    </PagesWrapper>
  );
}

export default СlassSchedule;
